/* eslint-disable array-callback-return */
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePineDataActions } from "../../store/uploadPineSlice";
import { getFilesUploadSizeFromDB } from "../../utility/commonFunction";
import FileUploadPopup from "../fileUploadPopup/organism/fileUploadPopup";
import CustomDivider from "./atoms/Divider";
import FolderAndDescriptionIcon from "./atoms/Icons/FolderAndDescriptionIcon";
import AssetDetail from "./atoms/Texts/AssetDetailText";
import UploadAssetText from "./atoms/Texts/UploadAssetText";
import UploadListedAssetText from "./atoms/Texts/UploadListedAssetText";
import UploadSummaryText from "./atoms/Texts/UploadSummaryText";
import { DELETING_NON_PLAEHOLDER_ERR_MSG, DUPLICATE_FILE_ERR_TITLE, INVALID_ZIP_FILE, MISSING_NON_PLAEHOLDER_WARN_MSG, PLACEHOLDER_FILE_UPLOAD_WARNING, REUPLOAD_WARN_MSG, TRY_UPLOADING_FILE_AGAIN, UPLAOD_FAILED_ERR_MSG } from "./constants/UploadMultipleAssetsContants";
import ContentPageBtnGrid from "./molecules/ContentPageBtnGrid";
import CustomPopover from "./molecules/CustomPopover";
import FileUpload from "./molecules/FileUpload";
import PageToggleWithGrid from "./molecules/PageToggleWithGrid";
import ScreenToggle from "./molecules/ScreenToggle";
import { CustomizedTables } from "./organism/customizedTables";
import ShowErrMsg from "./organism/showErrMsg";
import WarningAlerts from "./organism/warningAlerts";

const UploadMultipleAssets = () => {
  let initialIsProcessing2;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const linkedPineFolder = useSelector((state: any) => state?.updatePineData?.linkedPineFolder);
  const missingFileArray = useSelector((state: any) => state?.updatePineData?.missingFilesData);
  const selectedFileName = useSelector((state: any) => state?.updatePineData?.singleFileName);
  const selectedUploadFromStore = useSelector((state: any) => state?.updatePineData?.selectedUploadFrom);
  const csvFileData = useSelector((state: any) => state?.updatePineData?.csvFileData);
  let csvFileDataWithStatus = useSelector((state: any) => state?.updatePineData?.csvFileDataWithStatus);
  let missingCSVFilesData = useSelector((state: any) => state?.updatePineData?.missingFilesData);
  const selectedIsProcessingTab2 = useSelector((state: any) => state.updatePineData.isProcessingTab2);
  const allowedFileUploadSizeFromStore = useSelector((state: any) => state.updatePineData.allowedFileUploadSize);
  let removedUploadedFilesFromStore = useSelector((state: any) => state.updatePineData.removedUploadedFiles);
  const finalJsonFromStore = useSelector((state: any) => state?.updatePineData?.finalJson);
  const updatedJson = {
    csvdata: finalJsonFromStore?.csvdata?.map((item: any, index: any) => ({ ...item, row: index + 2 }))
  };
  const placeholderFilename = updatedJson?.csvdata?.filter((item: any) => {
    if (item?.filename && item?.placeholder === 'Y') return true;
    return false;
  });
  if (selectedIsProcessingTab2 && location?.state?.previousUrl === "/pine/showUploadedAssets") {
    initialIsProcessing2 = selectedIsProcessingTab2;
  } else {
    initialIsProcessing2 = Boolean(false);
  }
  const [selectedFiles, setSelectedFiles] = useState<Blob | File | string | any>([]);
  const [filteredFiles, setFilteredFiles] = useState<Blob | File | string | any>([]);
  const [placeholderUploadedArray, setPlaceholderUplaodedArray] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(initialIsProcessing2);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [totalNoOfPages, setTotalNoOfPages] = useState(25);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalFiles, setTotalFiles] = useState(1025);
  const [isMissingBtn, setIsMissingBtn] = useState(false);
  const [errCountLowerLimit, setErrCountLowerLimit] = useState(5);
  const [checkValidFileSizeErr, setCheckValidFileSizeErr] = useState(false);
  const [missingFileErr, setMissingFileErr] = useState(false);
  const [alreadyUploadedFileErr, setAlreadyUploadedFileErr] = useState(false);
  const [alreadyUploadedFileErr2, setAlreadyUploadedFileErr2] = useState(false);
  const [filesNotListedInMetada, setFilesNotListedInMetada] = useState<Blob | File | string | any>([]);
  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState<Blob | File | string | any>([]);
  const [uploadAgain, setUploadAgain] = useState(false);
  const [removeErrMsg, setRemoveErrMsg] = useState(false);
  const [filesWithDiffFoldernameErr, setFilesWithDiffFoldernameErr] = useState(false);
  const [filesWithDiffFoldername, setFilesWithDiffFoldername] = useState<Blob | File | string | any>([]);
  const [fileUploadErr, setFileUploadErr] = useState(false);
  const [signedUrlErr, setsignedUrlErr] = useState(false);
  const [deleteMissingFilesErr, setDeleteMissingFilesErr] = useState(false);
  const [failedUploadingFiles, setFailedUploadingFiles] = useState<Blob | File | string | any>([]);
  const [nonPlaceholderMissingWarning, setNonPlaceholderMissingWarning] = useState(false);
  const [isValidZipFile, setIsValidZipFile] = useState(true);
  const [zipValidationErrMsg, setZipValidationErrMsg] = useState<string | undefined | any>([]);
  const [checkFileAlreadyUploaded,setCheckFileAlreadyUploaded] = useState(false);
  const [placeHolderCheckarr, setPlaceHolderCheckarr] = useState<Blob | File | string |any>([]);
  const [mainFileCounter,setMainFileCounter] = useState<any>(0)
  const removingErrMsgWindow = () => {
    updateTheStatusInStore();
    setRemoveErrMsg(true);
    setAlreadyUploadedFileErr(false);
    setAlreadyUploadedFileErr2(false);
    setAlreadyUploadedFiles([]);
  }

  const enablingFileReupload = () => {
    setUploadAgain(true);
    setOpenModal(true);
    let alreadyUploadedFileNames: any[] = [];
    let commonItems: any[] = [];
    alreadyUploadedFileNames = alreadyUploadedFiles?.map((item: any) => {
      return item?.name;
    })
    if (removedUploadedFilesFromStore?.length > 0) {
      commonItems = removedUploadedFilesFromStore?.filter((item: any) => {
        return alreadyUploadedFileNames.includes(item?.fieldName)
      })
      removedUploadedFilesFromStore = removedUploadedFilesFromStore?.filter((item: any) => {
        let foundIndex = commonItems?.findIndex((item2: any) => {
          return item2?.fieldName === item?.fieldName
        })
        if (foundIndex === -1) {
          return item;
        }
        return false;
      })
    }
    console.log("enablingFileReupload",placeHolderCheckarr.length , checkFileAlreadyUploaded)
    dispatch(
      updatePineDataActions.setRemovedUploadedFiles({
        removedUploadedFiles: removedUploadedFilesFromStore,
      })
    );
  }

  const increamentCountHandler = () => {
    setErrCountLowerLimit((previous: any) => {
      return previous + 5;
    });
  };

  const fetchAndStoreAllowedUploadSize = async () => {
    let fetchedSize: any = await getFilesUploadSizeFromDB();
    dispatch(
      updatePineDataActions.updateAllowedFileUploadSize({
        allowedFileUploadSize: fetchedSize,
      })
    );
  }

  const convertAllowedUploadSize = () => {
    let finalAllowedSize: any = `${allowedFileUploadSizeFromStore}000000000`;
    return +finalAllowedSize;
  }

  const onChangeCheckForFileSizeErr = (onChangeSelectedFiles: any) => {
    // eslint-disable-next-line array-callback-return
    let totalFilesSize: any = Object.values(onChangeSelectedFiles).reduce(
      (totalSize, item: any) => {
        return (totalSize = totalSize + item.size);
      },
      0
    );
    let allowedUploadSize: any = convertAllowedUploadSize();
    if (totalFilesSize > allowedUploadSize) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeCheckForRequiredMetadataFiles = (
    onChangeSelectedFiles: any
  ) => {
    let notListedInMetaDataFiles: any[] = [];
    //let mainFilesArray: any [] = [];
    if (onChangeSelectedFiles.length > 0) {
      Object.values(onChangeSelectedFiles).forEach(async (file: any) => {
        let foundIndex = csvFileData.findIndex((item2: any) => {
          // if( (file.name === item2.fieldName) && item2.fileType === 'Video') {
          //   mainFilesArray.push(file)
          // }
          return file.name === item2.fieldName;
        });
        if (foundIndex === -1) {
          notListedInMetaDataFiles.push(file);
        }
      });
    }
    setFilesNotListedInMetada(notListedInMetaDataFiles);
    if (notListedInMetaDataFiles.length > 0) {
      if (notListedInMetaDataFiles?.length === onChangeSelectedFiles?.length) {
        return -1;
      }

      return 0;
    } else {
      return 1;
    }
  };

  const filteredFilesFromMetadata = (onChangeSelectedFiles: any) => {
    let filteredFiles: any[] = [];
    if (onChangeSelectedFiles.length > 0) {
      Object.values(onChangeSelectedFiles).forEach(async (file: any) => {
        let foundIndex = csvFileData.findIndex((item2: any) => {
          return file.name === item2.fieldName;
        });
        if (foundIndex !== -1) {
          filteredFiles.push(file);
        }
      });
    }
    return filteredFiles;
  };

  const checkNonUploadedFiles = (filteredFilesFromMetadataResult: any) => {
    let uploadedItem: any[] = [];
    let nonUploadedItem: any[] = [];
    if (filteredFilesFromMetadataResult?.length > 0) {
      Object.values(filteredFilesFromMetadataResult).forEach(
        async (file: any) => {
          let filename = file?.name;
          let fountIndex = [...csvFileDataWithStatus, ...removedUploadedFilesFromStore].findIndex((item: any) => {
            return (
              filename === item.fieldName &&
              item?.status &&
              item?.status === "Missing"
            );
          });
          if (fountIndex !== -1) {
            nonUploadedItem.push(file);
          } else {
            uploadedItem.push(file);
          }
          setAlreadyUploadedFiles(uploadedItem);
          if (nonUploadedItem.length === 0) {
            setAlreadyUploadedFileErr(true);
            setOpenModal(false);
          } else {
            setOpenModal(true);
            setFilteredFiles(nonUploadedItem);
          }
        }
      );
    }
  };

  const handleModalClose = () => {
    dispatch(
      updatePineDataActions.updateMultipleFileCount({ multipleFileCount: 0 })
    );
    setOpenModal(false);
  };

  const handleOnlyModalClose = () => {
    setOpenModal(false);
  };

  const enableAllContentPage = () => {
    setIsMissingBtn(false);
    setCurrentPageNo(0);
    dispatch(
      updatePineDataActions.updateMultipleStartValue({
        multipleStartValue: 0,
      })
    );
    dispatch(
      updatePineDataActions.updateMultipleEndValue({ multipleEndValue: 25 })
    );
  };

  const enableMissingContentPage = () => {
    setIsMissingBtn(true);
    setCurrentPageNo(0);
    dispatch(
      updatePineDataActions.updateMultipleStartValue({
        multipleStartValue: 0,
      })
    );
    dispatch(
      updatePineDataActions.updateMultipleEndValue({ multipleEndValue: 25 })
    );
  };

  const currentPageIncrement = () => {
    setCurrentPageNo(currentPageNo + 1);
  };

  const currentPageDecrement = () => {
    setCurrentPageNo(currentPageNo - 1);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getChildValues = (start: any, end: any, arrLength: any = 25) => {
    dispatch(
      updatePineDataActions.updateMultipleStartValue({
        multipleStartValue: start,
      })
    );
    dispatch(
      updatePineDataActions.updateMultipleEndValue({ multipleEndValue: end })
    );
    dispatch(
      updatePineDataActions.updateMultipleTotalValue({
        multipleTotalValue: arrLength,
      })
    );
  };

  const handleOnChange = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let filesArr: File[] = []
    const target = e.target as HTMLInputElement;
    if (target && target.files) {
      filesArr = Array.from(target.files);
    }
    console.log("handleOnChange condition file selected line no 306. uploadMultipleAssets",filesArr)

    setSelectedFiles(filesArr);
    setCheckValidFileSizeErr(false);
    setMissingFileErr(false);
    setAlreadyUploadedFileErr(false);
    setAlreadyUploadedFileErr2(false);
    setAlreadyUploadedFiles([]);
    setErrCountLowerLimit(5);
    setRemoveErrMsg(false);
    setUploadAgain(false);
    setFilesWithDiffFoldernameErr(false);
    setFilesWithDiffFoldername([]);
    setFileUploadErr(false);
    setDeleteMissingFilesErr(false)
    setFailedUploadingFiles([]);
    setNonPlaceholderMissingWarning(false)
    setIsValidZipFile(true)
    const onChangeCheckForFileSizeVariable = onChangeCheckForFileSizeErr(
      target.files
    );
    if (onChangeCheckForFileSizeVariable) {
      setCheckValidFileSizeErr(true);
      return;
    }
    if (selectedUploadFromStore === 'From Zip') {
      if (target?.files && (target?.files[0]?.type === 'application/zip' || target?.files[0]?.type === 'application/x-zip-compressed')) {
        setOpenModal(true)
      } else {
        setZipValidationErrMsg(INVALID_ZIP_FILE)
        setIsValidZipFile(false)
      }
    } else {
      const onChangeCheckMetaDataVariable = onChangeCheckForRequiredMetadataFiles(
        target.files
      );
      if (onChangeCheckMetaDataVariable === -1) {
        if (missingFileArray?.length <= 0) {
          let missingFilesArray: any = []
          csvFileData?.forEach((item: any) => {
            let obj: any = {}
            obj.mediaTitle = item.mediaTitle
            obj.fieldName = item.fieldName
            obj.status = "Missing"
            missingFilesArray.push(obj)
          })
          dispatch(
            updatePineDataActions.updateMissingFilesData({
              missingFilesData: missingFilesArray,
            })
          );
          dispatch(
            updatePineDataActions.updateCsvFileDataWithStatus({
              csvFileDataWithStatus: missingFilesArray,
            })
          );
        }
        setMissingFileErr(true);
        setNonPlaceholderMissingWarning(true)
        return;
      } else if (onChangeCheckMetaDataVariable === 0) {
        setMissingFileErr(true);
        //setNonPlaceholderMissingWarning(true)
        setOpenModal(true);
      } else {
        if (target?.files?.length !== 0) setOpenModal(true);
      }
      const filteredFilesFromMetadataResult = filteredFilesFromMetadata(
        target.files
      );
      setFilteredFiles(filteredFilesFromMetadataResult);
      if (csvFileDataWithStatus.length > 0) {
        checkNonUploadedFiles(filteredFilesFromMetadataResult);
      }
    }
    const element = e?.target as HTMLInputElement
    element.value = ''
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>
  ) => {
    e?.preventDefault();
  };

  const handleDrop = (
    e: React.DragEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>
  ) => {
    let filesArr: File[] = []
    e?.preventDefault();
    if (e.dataTransfer && e.dataTransfer.files) {
      filesArr.push(...Array.from(e?.dataTransfer?.files ));
    }
    console.log("handleDrop condition file selected line no 399. uploadMultipleAssets",filesArr)
    setSelectedFiles(filesArr);
    setCheckValidFileSizeErr(false);
    setMissingFileErr(false);
    setAlreadyUploadedFileErr(false);
    setAlreadyUploadedFileErr2(false);
    setAlreadyUploadedFiles([]);
    setErrCountLowerLimit(5);
    setRemoveErrMsg(false);
    setUploadAgain(false);
    setFilesWithDiffFoldernameErr(false);
    setFilesWithDiffFoldername([]);
    setFileUploadErr(false);
    setDeleteMissingFilesErr(false);
    setFailedUploadingFiles([]);
    setNonPlaceholderMissingWarning(false)
    setIsValidZipFile(true)
    const onChangeCheckForFileSizeVariable = onChangeCheckForFileSizeErr(
      e.dataTransfer.files
    );
    if (onChangeCheckForFileSizeVariable) {
      setCheckValidFileSizeErr(true);
      return;
    }
    if (selectedUploadFromStore === 'From Zip') {
      if (e?.dataTransfer?.files && (e.dataTransfer.files[0]?.type === 'application/zip' || e.dataTransfer.files[0]?.type === 'application/x-zip-compressed' )) {
        setOpenModal(true)
      } else {
        setZipValidationErrMsg(INVALID_ZIP_FILE)
        setIsValidZipFile(false)
      }
    } else {
      const onChangeCheckMetaDataVariable = onChangeCheckForRequiredMetadataFiles(
        e.dataTransfer.files
      );
      if (onChangeCheckMetaDataVariable === -1) {
        if (missingFileArray?.length <= 0) {
          let missingFilesArray: any = []
          csvFileData?.forEach((item: any) => {
            let obj: any = {}
            obj.mediaTitle = item.mediaTitle
            obj.fieldName = item.fieldName
            obj.status = "Missing"
            missingFilesArray.push(obj)
          })
          dispatch(
            updatePineDataActions.updateMissingFilesData({
              missingFilesData: missingFilesArray,
            })
          );
          dispatch(
            updatePineDataActions.updateCsvFileDataWithStatus({
              csvFileDataWithStatus: missingFilesArray,
            })
          );
        }
        setMissingFileErr(true);
        setNonPlaceholderMissingWarning(true)
        return;
      } else if (onChangeCheckMetaDataVariable === 0) {
        setMissingFileErr(true);
        //setNonPlaceholderMissingWarning(true)
        setOpenModal(true);
      } else {
        if (e?.dataTransfer?.files?.length !== 0) setOpenModal(true);
      }
      const filteredFilesFromMetadataResult = filteredFilesFromMetadata(
        e.dataTransfer.files
      );
      setFilteredFiles(filteredFilesFromMetadataResult);
      if (csvFileDataWithStatus.length > 0) {
        checkNonUploadedFiles(filteredFilesFromMetadataResult);
      }
    }
  };

  const checkForEmptyTableData = () => {
    if (csvFileDataWithStatus?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const updateTheStatusInStore = () => {
    let recentCSVFilesData;
    if (csvFileDataWithStatus?.length > 0) {
      recentCSVFilesData = csvFileDataWithStatus;
    } else {
      recentCSVFilesData = csvFileData;
    }
    let uploadedFilesArr: any[] = alreadyUploadedFiles?.map((item: any) => {
      return item.name;
    })
    let modifiedcsvFileDataWithStatus: any[] = recentCSVFilesData?.map((item: any) => {
      if (uploadedFilesArr?.includes(item?.fieldName)) {
        return {
          mediaTitle: item.mediaTitle,
          fieldName: item.fieldName,
          status: "Uploaded",
        };
      } else if (!item?.status) {
        return {
          mediaTitle: item.mediaTitle,
          fieldName: item.fieldName,
          status: "Missing",
        };
      } else {
        return item;
      }
    })
    let modifiedMissingCSVFilesData: any[] = modifiedcsvFileDataWithStatus?.filter((modifiedItem: any) => {
      return modifiedItem?.status === "Missing";
    });
    dispatch(
      updatePineDataActions.updateCsvFileDataWithStatus({
        csvFileDataWithStatus: modifiedcsvFileDataWithStatus,
      })
    );
    dispatch(
      updatePineDataActions.updateMissingFilesData({
        missingFilesData: modifiedMissingCSVFilesData,
      })
    );
    dispatch(
      updatePineDataActions.updateMultipleFileCount({
        multipleFileCount: Number(modifiedcsvFileDataWithStatus?.length - modifiedMissingCSVFilesData?.length),
      })
    );
    return true;
  }

  useEffect(() => {
    if (linkedPineFolder === "" || selectedFileName === "") {
      navigate("/pine");
    } else {
      fetchAndStoreAllowedUploadSize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (placeholderFilename?.length > 0 && csvFileDataWithStatus?.length > 0) {
      let warningMsgArray: any = []
      const filteredPlaceholderFiles = updatedJson?.csvdata?.filter((item: any) => {
        if (item?.filename && item.placeholder === 'Y')
          return item
      })
      filteredPlaceholderFiles.map((item: any) => {
        csvFileDataWithStatus.filter((item1: any) => {
          if (item.filename === item1.fieldName && item1.status === 'Uploaded') {
            warningMsgArray.push({ name: `Row ${item.row} - ${item.filename}` })
          }
        })
      })
      setPlaceholderUplaodedArray(warningMsgArray)
    }
  }, [placeholderFilename?.length, csvFileDataWithStatus, updatedJson?.csvdata])

  useEffect(() => {
    dispatch(
      updatePineDataActions.updateisProcessingTab2({
        isProcessingTab2: isProcessing,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  useEffect(() => {
    let totalArrItem;
    if (isMissingBtn) {
      totalArrItem = missingCSVFilesData?.length;
      setTotalFiles(missingCSVFilesData?.length);
    } else {
      totalArrItem = csvFileDataWithStatus?.length;
      setTotalFiles(csvFileDataWithStatus?.length);
    }
    let itemsPerPage = 25;
    let noOfPages = Math.ceil(totalArrItem / itemsPerPage);
    setTotalNoOfPages(noOfPages);
  }, [csvFileData, isMissingBtn, csvFileDataWithStatus, missingCSVFilesData]);

  useEffect(() => {
    console.log("came here",placeHolderCheckarr.length,checkFileAlreadyUploaded,uploadAgain,mainFileCounter)
    
    if(placeHolderCheckarr.length === mainFileCounter && placeHolderCheckarr.length !==0) {
      setNonPlaceholderMissingWarning(false)
    } else {
      if(placeHolderCheckarr.length === 0 && checkFileAlreadyUploaded) {
        setNonPlaceholderMissingWarning(true)
      } else {
        setNonPlaceholderMissingWarning(false)
      }

      if( (placeHolderCheckarr.length !== mainFileCounter) && (placeHolderCheckarr.length !==0) && checkFileAlreadyUploaded) {
        setNonPlaceholderMissingWarning(true)
      }
    }
    
  },[placeHolderCheckarr,checkFileAlreadyUploaded,uploadAgain,mainFileCounter])

  return (
    <>
      {openModal ? (
        <FileUploadPopup
          open={openModal}
          closeBtn={true}
          closeButtonClick={handleModalClose}
          handleOnlyModalClose={handleOnlyModalClose}
          title="Upload"
          okBtn="Done"
          cancelBtn="cancel"
          selectedFiles={selectedUploadFromStore === 'From Zip' ? selectedFiles : uploadAgain ? alreadyUploadedFiles : filteredFiles}
          setIsProcessing={setIsProcessing}
          setAlreadyUploadedFileErr2={setAlreadyUploadedFileErr2}
          setAlreadyUploadedFiles={setAlreadyUploadedFiles}
          setOpenModal={setOpenModal}
          uploadAgain={uploadAgain}
          removingErrMsgWindow={removingErrMsgWindow}
          setFilesWithDiffFoldernameErr={setFilesWithDiffFoldernameErr}
          setFilesWithDiffFoldername={setFilesWithDiffFoldername}
          setFileUploadErr={setFileUploadErr}
          setsignedUrlErr={setsignedUrlErr}
          setFailedUploadingFiles={setFailedUploadingFiles}
          setNonPlaceholderMissingWarning={setNonPlaceholderMissingWarning}
          setIsValidZipFile={setIsValidZipFile}
          setZipValidationErrMsg={setZipValidationErrMsg}
          setCheckFileAlreadyUploaded={setCheckFileAlreadyUploaded}
          setPlaceHolderCheckarr={setPlaceHolderCheckarr}
          checkFileAlreadyUploaded={checkFileAlreadyUploaded}
          placeHolderCheckarr={placeHolderCheckarr}
          setMainFileCounter={setMainFileCounter}
          mainFileCounter={mainFileCounter}
        />
      ) : null}
      <Grid container style={{ width: "500px", marginTop: "20px" }} data-testid="UploadMultipleAssets">
        <ScreenToggle
          selectedFiles={selectedFiles}
          setDeleteMissingFilesErr={setDeleteMissingFilesErr}
          signedUrlErr={signedUrlErr}
        />
        <CustomDivider />
        <FolderAndDescriptionIcon />
        <Grid
          container
          item
          marginBottom={isProcessing ? "10px" : "50px"}
          xs={12}
          sx={{
            borderRadius: "10px",
            marginTop: "2%",
            padding: "2%",
            backgroundColor: "white",
          }}
        >
          <UploadAssetText />
          <CustomPopover
            handleClick={handleClick}
            handleClose={handleClose}
            id={id}
            open={open}
            anchorEl={anchorEl}
          />
          <UploadListedAssetText />
          {checkValidFileSizeErr &&
            ShowErrMsg(`Upload failed, Your upload exceeded ${allowedFileUploadSizeFromStore}GB.`)}
          {missingFileErr &&
            ShowErrMsg(
              UPLAOD_FAILED_ERR_MSG,
              filesNotListedInMetada,
              errCountLowerLimit,
              increamentCountHandler
            )}
          {fileUploadErr &&
            ShowErrMsg(
              TRY_UPLOADING_FILE_AGAIN,
              failedUploadingFiles,
              errCountLowerLimit,
              increamentCountHandler
            )}
          {filesWithDiffFoldernameErr &&
            ShowErrMsg(
              DUPLICATE_FILE_ERR_TITLE,
              filesWithDiffFoldername,
              errCountLowerLimit,
              increamentCountHandler
            )}
          {alreadyUploadedFileErr && !removeErrMsg &&
            WarningAlerts(
              REUPLOAD_WARN_MSG,
              alreadyUploadedFiles,
              errCountLowerLimit,
              increamentCountHandler,
              enablingFileReupload,
              removingErrMsgWindow,
            )}
          {alreadyUploadedFileErr2 && !removeErrMsg &&
            WarningAlerts(
              REUPLOAD_WARN_MSG,
              alreadyUploadedFiles,
              errCountLowerLimit,
              increamentCountHandler,
              enablingFileReupload,
              removingErrMsgWindow,
            )}
          {deleteMissingFilesErr &&
            ShowErrMsg(
              DELETING_NON_PLAEHOLDER_ERR_MSG,
              alreadyUploadedFiles,
              errCountLowerLimit,
              increamentCountHandler,
              enablingFileReupload,
              removingErrMsgWindow,
            )}
            {((!uploadAgain && nonPlaceholderMissingWarning) || (placeHolderCheckarr.length === 0 && checkFileAlreadyUploaded && nonPlaceholderMissingWarning)) &&
            WarningAlerts(
              MISSING_NON_PLAEHOLDER_WARN_MSG
            )}
          {!isValidZipFile &&
            ShowErrMsg(
              zipValidationErrMsg || INVALID_ZIP_FILE
            )}
          {placeholderUploadedArray?.length > 0 &&
            WarningAlerts(
              PLACEHOLDER_FILE_UPLOAD_WARNING,
              placeholderUploadedArray,
              errCountLowerLimit,
              increamentCountHandler,
              enablingFileReupload,
              removingErrMsgWindow,
            )}
          <FileUpload
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleOnChange={handleOnChange}
          />
        </Grid>
        {isProcessing && checkForEmptyTableData() && (
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: "10px",
              marginBottom: "50px",
              padding: "2%",
              backgroundColor: "white",
            }}
          >
            <UploadSummaryText />
            <AssetDetail />
            <ContentPageBtnGrid
              isMissingBtn={isMissingBtn}
              enableAllContentPage={enableAllContentPage}
              enableMissingContentPage={enableMissingContentPage}
              currentPageDecrement={currentPageDecrement}
              currentPageIncrement={currentPageIncrement}
            />
            <Grid item sx={{ marginTop: "3%" }}>
              <CustomizedTables
                currentPageNo={currentPageNo}
                totalNoOfPages={totalNoOfPages}
                isMissingBtn={isMissingBtn}
                csvFileDataWithStatus={csvFileDataWithStatus}
                missingCSVFilesData={missingCSVFilesData}
                getChildValues={getChildValues}
              />
            </Grid>
            <PageToggleWithGrid
              isMissingBtn={isMissingBtn}
              currentPageDecrement={currentPageDecrement}
              currentPageIncrement={currentPageIncrement}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UploadMultipleAssets;

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../../../constants/appConstant";
import { NEXT } from "../../constants/UploadMultipleAssetsContants";
import { updatePineDataActions } from "../../../../store/uploadPineSlice";

interface Props {
  selectedFiles?: any,
  setDeleteMissingFilesErr?: any
  signedUrlErr?: any
}
const NextPageButton = ({ selectedFiles, setDeleteMissingFilesErr,signedUrlErr }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch =  useDispatch()
  const csvFileDataWithStatus = useSelector(
    (state: any) => state.updatePineData.csvFileDataWithStatus
  );
  const missingFileArray = useSelector(
    (state: any) => state.updatePineData.missingFilesData
  );
  const finalJsonFromStore = useSelector(
    (state: any) => state.updatePineData.finalJson
  );
  const filenameJobIdCreatedAtArray = useSelector(
    (state: any) => state.updatePineData.filenameWithJobIdCreatedAtArray
  );
  const allFilesJobIdCreatedAtArray = useSelector(
    (state: any) => state.updatePineData.allFilesWithJobIdCreatedAtArray
  );
  const isNonPlaceholder = finalJsonFromStore?.csvdata?.some((item: any) => {
    return item.placeholder === 'N'
  })
  const selectedUploadFromStore = useSelector(
    (state: any) => state.updatePineData.selectedUploadFrom
  );
  const untrackedFilesFromStore = useSelector(
    (state: any) => state.updatePineData.untrackedFiles
  );

  const checkForNonPlaceholderMissingFiles = async () => {
    let jobIdCreatedArray: any = []
    if (missingFileArray.length > 0) {
      filenameJobIdCreatedAtArray?.forEach((item: any) => {
        missingFileArray?.forEach((missingFile: any) => {
          if (item.filename === missingFile.fieldName) {
            let obj: any = {}
            obj.job_id = item.job_id
            obj.created_at = item.created_at
            jobIdCreatedArray.push(obj)
          }
        })
      })
      jobIdCreatedArray.length > 0 ? await deleteNonPlaceholderMissingFile(jobIdCreatedArray) : navigate("/pine/showUploadedAssets");
    } else {
      navigate("/pine/showUploadedAssets")
    }
  }

  const navigateToNextPage = async () => {
    let finalJson = { ...JSON.parse(JSON.stringify(finalJsonFromStore)) }
    if (selectedUploadFromStore === 'From Local') finalJson = { ...finalJson, isUpload: true }
    if (selectedUploadFromStore === 'From Zip') {
      delete finalJson.isUpload
      finalJson.untrackedFile = untrackedFilesFromStore
    }
    let missingFilesWithTypeArray = allFilesJobIdCreatedAtArray?.filter((file: any) => {
      return missingFileArray?.some((missingFile: any) => missingFile.fieldName === file.filename);
    });

    // Filter out non-placeholder missing files and set missing supporting files to empty
    finalJson.csvdata = finalJson.csvdata?.filter((file: any) => {
      if (missingFilesWithTypeArray?.some((missingItem: any) => missingItem.filename === file.filename && file.placeholder === 'N' && !missingItem.alreadyUploaded)) {
        return false; // Filter out the item if it's a non-placeholer missing and first time uploaded
      } else {
        missingFilesWithTypeArray?.forEach((missingItem: any) => {
          if (missingItem.type !== 'filename' && file[missingItem.type].includes(missingItem.filename)) {
            if (missingItem.type === 'captionFileNames' || missingItem.type === 'otherFileNames') {
              file[missingItem.type] = file[missingItem.type]?.split(';')?.filter((item: any) => item !== missingItem.filename).join(';')
            } else {
              file[missingItem.type] = ''; //Set missing supporting file to empty
            }
          }
        });
        return true; // Keep the item
      }
    });
    if (finalJson?.csvdata?.length > 0) {
      try {
        let config = {
          method: "post",
          url: selectedUploadFromStore === 'From Local' ? `${apiEndpoint}/saveUploadCsvToS3` : `${apiEndpoint}/moveZipFilesAndMetadata`, // zip last api
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(finalJson)
        };

        axios(config)
          .then(async function (response: any) {
            selectedUploadFromStore === 'From Zip'&& dispatch(
              updatePineDataActions.updateUploadReportFlag({
                uploadReportFlag: true,
              })
            );
            checkForNonPlaceholderMissingFiles()
          })
          .catch(function (error: any) {
            console.log("err : ", error);
          });
      } catch (err) {
        console.log("Error ocured while uploading CSV file", err);
      }
    } else {
      checkForNonPlaceholderMissingFiles()
    }

  }
  const deleteNonPlaceholderMissingFile = async (jobIdCreatedArray: any) => {

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiEndpoint}/deleteDataLocalToS3`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ deleteData: jobIdCreatedArray }),
    };

    axios.request(config)
      .then((response: any) => {
        navigate("/pine/showUploadedAssets");
      })
      .catch((error: any) => {
        setDeleteMissingFilesErr(true)
        console.log('delete data Local to S3 api error : ', error);
      });
  }

  const checkForNextBtnDisableCondition = () => {
    let returnData;
    if (csvFileDataWithStatus && csvFileDataWithStatus.length > 0) {
      returnData =  true;
    } else if (!isNonPlaceholder || selectedFiles.length !== 0) {
      returnData = true
    } else {
      returnData =  false;
    }
     if(signedUrlErr) {
      returnData =  false
    }
    return returnData
  };

  return (
    <Button
      sx={{
        fontSize: "10px",
        background: "#006684",
        height: "30px",
        color: "#FFFFFF",
        cursor: "pointer",
      }}
      variant="contained"
      size="small"
      disabled={checkForNextBtnDisableCondition() ? false : true}
      onClick={() => {
        navigateToNextPage();
      }}
      data-testid="NextPageButton"
    >
      {" "}
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "12px",
          fontWeight: "600",
        }}
      >
        {NEXT}
      </Typography>
      <NavigateNextIcon
        fontSize="small"
        sx={{
          fontSize: "15px",
          marginRight: "-5px",
          paddingLeft: "5px",
        }}
      />
    </Button>
  )
}

export default NextPageButton
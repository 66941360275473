import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";

const UploadPineTab = () => {
  
  const navigate = useNavigate();
  const selectedLOBValue: any = useSelector(
    (state: any) => state?.updatePineData?.selectedLOBValue
  );
  const allowedFileUploadSizeFromStore = useSelector(
    (state: any) => state.updatePineData.allowedFileUploadSize
  );
  
  useEffect(() => {
    if (selectedLOBValue === "") navigate("/pine");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExcelFileDownload = () => {
    const filename = "Sample_metadatasheet.csv";
    const anchorLink = document.createElement("a");
    anchorLink.href = filename;
    anchorLink.download = "Sample_metadatasheet";
    document.body.appendChild(anchorLink);
    anchorLink.click();
    anchorLink?.parentNode?.removeChild(anchorLink);
  };

  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop="30px"
      data-testid="UploadPineTab"
    >
      <Grid
        item
        xs={10}
        sm={7}
        md={8}
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "2%",
          width: "500px",
        }}
      >
        <Grid item sx={{ marginLeft: "1%" }}>
          <Typography gutterBottom sx={{ fontSize: "18px" }}>
            Start here
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "1%" }}>
          <Typography gutterBottom sx={{ fontSize: "12px" }}>
            Edit the metadata file using the spreadsheet editor of your choice
            (Microsoft Excel, Google sheets or Apple Numbers). Required fields
            are marked in red.
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          sx={{ fontSize: "13px", marginTop: "1.5%", fontWeight: "700" }}
        >
          <Grid item sx={{ padding: "1%" }}>
            <b>Note before uploading : </b>
          </Grid>
        </Grid>
        <Grid item container direction="row" sx={{ fontSize: "12px" }}>
          <Grid item sx={{ padding: "0.4%" }}>
            <ul>
              <li>
                Accepted file types for bulk upload includes video, standalone
                Audio, Thumbnails, Captions (.Vtt), Audio descriptors files and
                Nav.xml.
              </li>
              <li>{`Size limit for uploads using the local pathway is ${allowedFileUploadSizeFromStore} GB.`}</li>
              <li>There is a limit of 100 lines per metadata sheet for uploads using the local pathway.</li>
            </ul>
          </Grid>
        </Grid>
        <Grid item display="flex" justifyContent="end">
          <Button
            // sx={downloadcsvButtonStyle}
            onClick={handleExcelFileDownload}
            variant="outlined"
            size="small"
            sx={{ color: "#006684" }}
            data-testid="ExcelFileDownload"
          >
            <SaveAltIcon fontSize="small" sx={{ paddingX: "5px" }} />
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              Download metadata file
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={10}
        sm={7}
        md={8}
        sx={{
          marginY: 2,
          display: "flex",
          justifyContent: "end",
          width: "500px",
        }}
      >
        <Link to="/pine/addPineFolder" style={{ textDecoration: "none" }}>
          <Button
            sx={{
              fontSize: "12px",
              backgroundColor: "#006684",
              color: "#FFFFFF",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#006684",
              },
            }}
            variant="contained"
            size="small"
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              Begin Upload Process
            </Typography>
            <NavigateNextIcon fontSize="small" />
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default UploadPineTab;
